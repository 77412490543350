





import {
  Component,
  ProvideReactive,
  Prop,
  Vue,
  Watch,
  Emit,
} from "vue-property-decorator";
import { mapGetters } from "vuex";
import { eventHandler, translations } from "@/mixins";
import CategoryTimeEvents from "./timedevents/CategoryTimedEvents.vue";

@Component({
  components: {
    CategoryTimeEvents
  },
  computed: {
    ...mapGetters({
      getUser: 'account/getUser'
    })
  },
  mixins: [eventHandler, translations],
})
export default class TimeEvents extends Vue {

  @Prop() active!: Boolean
  open: Boolean = false;
  translate!: Function;



}
